export const ensureForwardSlash = (raw: string): string => {
    let url = raw

    const isFile = /\/[^/]+\.[^/]+$/
    const strippedDomain = raw.replace(/.*\/\/[^/]*/, '')

    if (
        !raw.startsWith('mailto:') &&
        !raw.startsWith('tel:') &&
        !strippedDomain.match(isFile) &&
        raw === strippedDomain
    ) {
        url = url.replace(/\/?(.*)/, '/$1')
    }

    return url
}
