import { PageUrl } from '@application/Types/ComponentTypes'

import { ensureForwardSlash } from '../../Infrastructure/Url/ensureForwardSlash'

type SortArrayObjectByProp = <T>(getValue: (t: T) => number) => (a: T, b: T) => number

export const sortingArray: SortArrayObjectByProp = (getValue) => (a, b) => getValue(a) - getValue(b)

export const getPageUrl = <T extends PageUrl>(pageUrl: T): string => {
    return ensureForwardSlash(
        pageUrl.useLegacyPageUrl && pageUrl.legacyPageUrl ? pageUrl.legacyPageUrl : pageUrl.pageUrl
    )
}

export const parseUtmParamsInHtml = (html = ''): string => {
    let text = html

    const matchers = text.match(/@utm\w+/g)
    matchers?.map((query) => {
        text = text.replace(query, `<span id="${query}"></span>`)
    })

    return text
}

export const convertPathToTitle = (path: string): string => {
    // Remove leading and trailing slashes
    path = path.replace(/^\/|\/$/g, '')

    // Split the path into segments
    let segments = path.split('/')

    // Capitalize each segment
    segments = segments.map((segment) => {
        return segment
            .replace(/[-_]/g, ' ') // Replace hyphens and underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    })

    // Join the segments back into a string
    return segments.join(' - ')
}
