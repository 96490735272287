exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-410-tsx": () => import("./../../../src/pages/410.tsx" /* webpackChunkName: "component---src-pages-410-tsx" */),
  "component---src-templates-sitemap-template-tsx": () => import("./../../../src/templates/sitemap/Template.tsx" /* webpackChunkName: "component---src-templates-sitemap-template-tsx" */),
  "component---src-templates-strapi-blog-template-article-tsx": () => import("./../../../src/templates/strapi-blog/TemplateArticle.tsx" /* webpackChunkName: "component---src-templates-strapi-blog-template-article-tsx" */),
  "component---src-templates-strapi-blog-template-list-tsx": () => import("./../../../src/templates/strapi-blog/TemplateList.tsx" /* webpackChunkName: "component---src-templates-strapi-blog-template-list-tsx" */),
  "component---src-templates-strapi-freight-resources-template-tsx": () => import("./../../../src/templates/strapi-freight-resources/Template.tsx" /* webpackChunkName: "component---src-templates-strapi-freight-resources-template-tsx" */),
  "component---src-templates-strapi-global-freight-template-tsx": () => import("./../../../src/templates/strapi-global-freight/Template.tsx" /* webpackChunkName: "component---src-templates-strapi-global-freight-template-tsx" */),
  "component---src-templates-strapi-help-center-template-article-tsx": () => import("./../../../src/templates/strapi-help-center/TemplateArticle.tsx" /* webpackChunkName: "component---src-templates-strapi-help-center-template-article-tsx" */),
  "component---src-templates-strapi-help-center-template-list-tsx": () => import("./../../../src/templates/strapi-help-center/TemplateList.tsx" /* webpackChunkName: "component---src-templates-strapi-help-center-template-list-tsx" */),
  "component---src-templates-strapi-help-center-template-tsx": () => import("./../../../src/templates/strapi-help-center/Template.tsx" /* webpackChunkName: "component---src-templates-strapi-help-center-template-tsx" */),
  "component---src-templates-strapi-page-template-tsx": () => import("./../../../src/templates/strapi-page/Template.tsx" /* webpackChunkName: "component---src-templates-strapi-page-template-tsx" */)
}

