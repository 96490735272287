const { getLocaleCookie, setLocaleCookie } = require('./src/Infrastructure/Cookies')
const { ensureForwardSlash } = require('./src/Infrastructure/Url/ensureForwardSlash')
const { ensureTrailingSlash } = require('./src/Infrastructure/Url/ensureTrailingSlash')
const { getPageUrl } = require('./src/Application/Utils/generalUtils')

exports.disableCorePrefetching = () => true

const SupportedLocales = ['en', 'es', 'de', 'nl']

exports.wrapPageElement = ({ element, props }) => {
    const { location, data } = props

    if (typeof window !== 'undefined') {
        const detected = getLocaleCookie()
        const queryParams = location.search || ''

        const langPrefix = detected !== 'en' ? `/${detected}` : ''

        const gatsbyPathPrefix = process.env.GATSBY_PATH_PREFIX
            ? ensureForwardSlash(process.env.GATSBY_PATH_PREFIX)
            : ''

        const purePathname = location.pathname.replace(gatsbyPathPrefix, '')
        const pathnameLang = purePathname.match(/^\/\w{2}\//g)?.[0]
        const lang = pathnameLang?.replace(/\//g, '') || 'en'
        const alternatePage = data?.strapiPage?.alternateUrls?.find((alternate) => alternate.locale === detected)
        const alternateUrl = alternatePage ? getPageUrl(alternatePage) : null

        // if detected language is not supported then redirect to default /
        if (detected && !SupportedLocales.includes(detected)) {
            setLocaleCookie('en')

            window.___replace(`${gatsbyPathPrefix}/${queryParams}${location.hash}`)

            return null
        }

        if (detected && detected !== lang && SupportedLocales.includes(lang)) {
            let newUrl
            if (alternateUrl) {
                newUrl = `${gatsbyPathPrefix}${langPrefix}${ensureForwardSlash(
                    ensureTrailingSlash(alternateUrl)
                )}${queryParams}${location.hash}`

                setLocaleCookie(detected)

                window.___replace(newUrl)

                return null
            } else {
                setLocaleCookie(props.pageContext.language)
            }
        }

        // for users using website for the first time
        if (!detected) {
            setLocaleCookie(props.pageContext.language)
        }
    }

    exports.onRouteUpdate = ({ location }) => {
        const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries())

        const utmElements = Array.from(document.querySelectorAll(`[id^="@utm"]`))

        utmElements.map((element) => {
            const id = element.id.replace('@', '')

            if (searchParams[id]) {
                element.parentNode?.replaceChild(document.createTextNode(searchParams[id]), element)
            }
        })
    }

    return element
}
