import mixpanel from 'mixpanel-browser'

import { isGdprCookieAccepted } from '@infrastructure/Cookies'

import { MixpanelTrackingEvents } from './types'

export type MixpanelType = 'mixpanel'

export const mixpanelLoadedCallback = (): void => {
    startTracking()
}

export const initMixpanel = (key: string): void => {
    mixpanel.init(key, {
        opt_out_tracking_by_default: true,
        opt_out_persistence_by_default: true,
        loaded: mixpanelLoadedCallback,
    })
}

export const startTracking = (): void => {
    const gdprCookie = isGdprCookieAccepted('mixpanel')

    if (gdprCookie) {
        mixpanel.opt_in_tracking()
        mixpanel.track_pageview()
    }
}

export const shouldSendEventsToMixpanel = (): boolean => {
    try {
        return mixpanel.has_opted_in_tracking()
    } catch {
        return false
    }
}

export const sendEventToMixpanel = (props: MixpanelTrackingEvents) => {
    if (shouldSendEventsToMixpanel()) {
        const { eventName, ...rest } = props
        mixpanel.track(eventName, rest)
    }
}
