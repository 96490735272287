import { initializeSourceBuster } from '../../src/Infrastructure/SourceBuster'

type Options = {
    domain: string
}

export const onInitialClientRender = (_: undefined, options: Options): void => {
    const { domain } = options

    initializeSourceBuster(domain)
}
