import { initMixpanel } from '@infrastructure/Mixpanel'

type Options = {
    key: string
}

export const onInitialClientRender = (_: undefined, options: Options): void => {
    const { key } = options

    initMixpanel(key)
}
