export const ensureTrailingSlash = (raw: string): string => {
    let url = raw

    const isFile = /\/[^/]+\.[^/]+$/
    const strippedDomain = raw.replace(/.*\/\/[^/]*/, '')

    if (!strippedDomain.match(isFile)) {
        url = url.replace(/\/?(\?|#|$)/, '/$1')
    }

    return url
}
