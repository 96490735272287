export const LANGUAGE_COOKIE_NAME = 'id_lang'
export const OLD_GDPR_COOKIE_NAME = 'cookiePolicyTracker'
export const GDPR_MANDATORY_COOKIE_NAME = 'mandatoryCookieTracker'
export const GDPR_PERFORMANCE_COOKIE_NAME = 'performanceCookieTracker'
export const GDPR_MARKETING_COOKIE_NAME = 'marketingCookieTracker'
export const GDPR_COOKIES_EXPIRES_IN_DAYS = 180

export const ICONTAINERS_COOKIE_DOMAIN = `${process.env.GATSBY_COOKIE_DOMAIN}`

export const VISITOR_UUID_COOKIE_NAME = 'iconVisitorUuid'

export const VISITOR_UUID_COOKIES_EXPIRES_IN_DAYS = 365
